(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const downloadJs = require('downloadjs');

    class formsService {
        constructor($q, $http, mnWebSocket, $state, $mdToast) {
            this.q = $q;
            this.http = $http;
            this.toast = $mdToast;
            this.ws = mnWebSocket;
            this.$state = $state;
            this.newFormSubject = new Subject();
            this.editFormSubject = new Subject();
            this.deleteFormSubject = new Subject();
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$state", "$mdToast"];
        }

        getForms(pk) {
            const deferred = this.q.defer();
            let url = `/api/patient-form-template/?patient=${pk}`;
            this.http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        getGeneralForms() {
            const deferred = this.q.defer();

            this.http
                .get("/api/general-form-template/")
                .then(response => deferred.resolve(response.data), deferred.reject);
            return deferred.promise;
        }

        #getItem(pk, view) {
            const deferred = this.q.defer();
            let url = `/api/${view}/${pk}/`;
            this.http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getForm(pk) {
            return this.#getItem(pk, "patient-form-template");
        }

        getGeneralForm(pk) {
            return this.#getItem(pk, "general-form-template");
        }

        #saveItem(f, view) {
            let deferred = this.q.defer();
            let url = `/api/${view}/${!_.isNil(f.id) ? f.id + "/" : ""}`;
            this.http
                .post(url, f)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise
        }

        saveForm(f) {
            return this.#saveItem(f, "patient-form-template");
        }

        saveGeneralForm(f) {
            return this.#saveItem(f, "general-form-template");
        }

        #deleteItem(pk, view) {
            let deferred = this.q.defer();
            let url = `/api/${view}/${pk}/`;

            this.http
                .delete(url)
                .then(response => {
                    this.deleteFormSubject.next(pk);

                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise
        }

        deleteForm(pk) {
            return this.#deleteItem(pk, "patient-form-template");
        }

        deleteGeneralForm(pk) {
            return this.#deleteItem(pk, "general-form-template");
        }

        #_editState(state, item, reload = true, template = null) {
            let params = {
                form: _.get(item, 'id', null),
                template: template
            }

            this.$state.go(state, params, {
                inherit: true
            }).then(reload ? this.$state.reload : _.noop, _.noop);
        }

        editState(item, reload = true, template = null) {
            this.#_editState("app.visit.forms.detail", item, reload, template);
        }

        editGeneralFormState(item, reload = true, template = null) {
            this.#_editState("app.forms.detail", item, reload, template)
        }

        saveFormTemplate(t) {
            let deferred = this.q.defer();
            let url = `/api/form-template/${!_.isNil(t.id) ? t.id + "/" : ""}`;
            this.http.post(url, t)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        getFormsTemplates(pk = null) {
            let deferred = this.q.defer();
            this.http.get(`/api/form-template/${pk ? `${pk}/` : ""}`)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise
        }

        deleteFormTemplate(pk) {
            let deferred = this.q.defer();
            let url = `/api/form-template/${pk}/`;

            this.http.delete(url)
                .then(success.bind(this), deferred.reject);

            function success(response) {
                this.deleteFormSubject.next(pk);

                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        exportFormTemplate(pk) {
            let deferred = this.q.defer();

            const promise = this.http.get(`/api/form-template/${pk}/export/`, {responseType: 'arraybuffer'})
                .then(res => {
                    downloadJs(res.data, res.headers('x-filename'));

                    deferred.resolve(true)
                }, _.noop);
            const toast = this.toast.mnAdvancedToast()
                .handle(promise)
                .label('app_backup.downloading');

            this.toast.show(toast);

            return deferred.promise
        }

        updateTemplates(ts) {
            this.ws.pub("forms.FormTemplate.reload_templates", {reload: true}, true)
        }

        getValueForEditor(field) {
            switch (field.type) {
                case "text":
                case "date":
                case "formula":
                case "radio-button":
                case "list":
                    return !!field.value ? field.value : "";
                case "long-text":
                    const value = !!field.value ? field.value : "";
                    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
                case "number":
                    const fix = _.get(field, "options.decimals", 2);
                    return !!field.value ? field.value.toFixed(fix) : "";
                case "checkbox":
                    return !!field.value ? field.value.join(", ") : "";
            }

            return ""
        }

        templateAsHtmlBlock(template) {
            return this.ws.call("forms.FormTemplate.save_as_html_block", {template});
        }

        templateByPatient(patient, pk) {
            return this.ws.call("forms.PatientFormTemplate.by_patient", {patient, pk});
        }

        getFormTemplateCategories() {
            let deferred = this.q.defer();
            let url = "/api/form-template-category/";

            this.http.get(url)
                .then(response => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        saveFormTemplateCategory(c) {
            let deferred = this.q.defer();
            let url = `/api/form-template-category/${!_.isNil(c.id) ? c.id + "/" : ""}`;

            this.http.post(url, c)
                .then(response => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }
    }

    module.exports = formsService;
})();
