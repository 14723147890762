/**
 * Created by Betalos on 24/07/2016.
 */
(function () {

    'use strict';

    const VISUALIZE_FILE = require('stand-alone/file-manager/dialogs/visualize-file-dialog');
    const {APPOINTMENT, DELETE} = require('stand-alone/calendar/utils/consts');

    class EntryResumeCtrl {
        constructor(
            $mdDialog, patientService, system, $q, frontDeskService, $translate, $timeout, mnWebSocket,
            prescriptionService, measureService, configService, dentalService, dentalUtilsService, moment,
            $element, $auth, $scope, billingService, $mdToast, authService
        ) {
            this.$q = $q;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.timeout = $timeout;
            this.toast = $mdToast;
            this.auth = $auth;
            this.scope = $scope;
            this.element = $element;

            this.configService = configService;
            this.dentalService = dentalService;
            this.patientService = patientService;
            this.measureService = measureService;
            this.frontDeskService = frontDeskService;
            this.dentalUtilsService = dentalUtilsService;
            this.prescriptionService = prescriptionService;
            this.billingService = billingService;

            this.disabledTab = [];
            this.dateTimeFormat = system['date_format'].naive;

            this.fileFilter = {};
            this.filesTab = false;
            this.isDental = this.configService.isDental() && authService.aclHandler({
                resource: 'dental',
                action: 'get'
            });
            this.callbacks = {
                uploadFiles: (ev, context) => this.uploadFiles(ev, context),
                toothHistoric: (tooth, ev) => this.toothHistoric(tooth, ev),
                showContextFiles: (context) => this.showContextFiles(context)
            };

            // appointment table callbacks
            this.onPaginate = _.mnDelay(() => this.onPaginateCall(), 100);

            // dental related objects
            this.plans = [];
            this.condition = [];
            this.consultations = [];
            this.dentalTab = false;
            this.chartID = _.uniqueId('svg-parent-');

            this.editAppointment = (appointment, ev) => frontDeskService.editAppointment(appointment, ev);

            this.paginationLabel = {
                of: $translate['instant']('of'),
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
            };

            // toast related
            this.simpleToast = $mdToast.simple()
                .textContent($translate['instant']('entry_text_copied_to_clipboard'))
                .position("bottom left")
                .hideDelay(750);
        }

        static get $inject() {
            return [
                "$mdDialog", "patientService", "system", "$q", "frontDeskService", "$translate", "$timeout",
                "mnWebSocket", "prescriptionService", "measureService", "configService", "dentalService",
                "dentalUtilsService", "moment", "$element", "$auth", "$scope", "billingService", "$mdToast",
                "authService"
            ];
        }

        $onInit() {
            this.currentPatient = this['patient-id'];
            this.entry = this.entry ? this.entry : null;
            this.query = {page: 1, limit: 10, patient: this.currentPatient};

            this.relations = [];
            this.relationTypes = [
                {value: 'PARENT', label: "patient_relations_parent", index: 0},
                {value: 'SPOUSE', label: "patient_relations_spouse", index: 1},
                {value: 'CHILD', label: "patient_relations_child", index: 2},
                {value: 'SIBLING', label: "patient_relations_sibling", index: 3},
                {value: 'NURSE', label: "patient_relations_nurse", index: 4}
            ];

            this.onlyResume = this.onlyResume || this.entry;
            if (this.entry) this.generateEntryResume();

            let promises = [
                this.patientService.getFormPatient(this.currentPatient),
                this.patientService.summaryFile(this.currentPatient),
                this.frontDeskService.patientAppointments(this.query),
                this.prescriptionService.getLastMedicalPrescription(this.currentPatient),
                this.configService.get(["practice_payment", "entry_resume_config"], true),
                this.billingService.getPatientFullContract(this.currentPatient),
                this.patientService.getPatientRelations(this.currentPatient)
            ];

            if (this.isDental) {
                promises = _.concat(promises, [
                    this.dentalService.getPatientPlans(this.currentPatient),
                    this.dentalService.getPatientCondition(this.currentPatient),
                    this.dentalService.getPatientConsultations(this.currentPatient)
                ]);
            }

            this.promise = this.$q.all(promises)
                .then(data => {
                    this.patient = data[0];
                    this.imageSrc = `/api/patient/${this.patient.id}/data/?t=${this.moment().valueOf()}`;

                    this.patientBalance = this.getPatientBalance(data);

                    this.summaryFile = data[1];
                    this.size = data[2]['count'];
                    this.appointments = data[2]['items'];

                    this.prescription = data[3];

                    this.patientContract = data[5];
                    this.paymentConfig = _.get(data, '4.practice_payment');

                    this.disabledTab = _.get(data, '4.entry_resume_config.disabled_tab', []) || [];
                    this.defaultTab = _.get(data, '4.entry_resume_config.default_tab') || {default_tab: 'PATIENT_DEMOGRAPHICS'};

                    this.relations = _.chain(data[6]).map(e => {
                        let r = e.direction === 'left' ? e.relation : e.inverse_relation;
                        return {
                            relation: r,
                            member: e.direction === 'left' ? e.right : e.left,
                            idx: _.get(_.find(this.relationTypes, {value: r}), 'index', 10)
                        }
                    }).sortBy('idx').value();

                    if (this.isDental) {
                        this.plans = data[7];
                        this.condition = data[8];
                        this.consultations = data[9];

                        this.fileFilters = this.dentalUtilsService.handleFilters(this.consultations, this.plans);
                        this.dentalUtilsService.proceduresApplied('procedureDone', this.chartID, this.condition, this.consultations)
                            .then(style => this.schema = style);
                    }

                    this.imageSrc = `/api/patient/${this.patient.id}/data/?t=${this.moment().valueOf()}`;
                });
        }

        getPatientBalance(data) {
            const patientBalance = _.get(data, '0.general_account');
            const showOnlyNotValidated = _.get(data, '4.practice_payment.balance_only_not_validated');

            return _.assign({}, patientBalance, showOnlyNotValidated ? {
                amount_received: patientBalance['amount_received_without_payed'],
                amount_consumed: patientBalance['amount_consumed_without_payed'],
                dental_progress: patientBalance['dental_progress_without_payed'],
            } : {});
        }

        cancel(use = false) {
            this.dialog.cancel(use);
        }

        generateEntryResume() {
            const entryTime = this.moment(this.entry['entry_time'], this.dateTimeFormat);
            const visitStartTime = this.moment(this.entry['visit_start_time'], this.dateTimeFormat);
            const visitEndTime = this.moment(this.entry['visit_end_time'], this.dateTimeFormat);
            const exitTime = this.moment(this.entry['exit_time'], this.dateTimeFormat);

            if (!!this.entry['entry_time'] && !!this.entry['visit_start_time']) {
                this.waitingTime = _.capitalize(this.moment.duration(visitStartTime.diff(entryTime)).humanize());
                this.visitTime = _.capitalize(this.moment.duration(visitStartTime.diff(this.moment())).humanize());
            } else if (this.entry['exit_time']) this.waitingTime = _.capitalize(this.moment.duration(exitTime.diff(entryTime)).humanize());
            else this.waitingTime = _.capitalize(this.moment.duration(this.moment().diff(entryTime)).humanize());

            if (!!this.entry['visit_start_time'] && !!this.entry['visit_end_time']) {
                this.visitTime = _.capitalize(this.moment.duration(visitEndTime.diff(visitStartTime)).humanize());
            } else this.visitTime = _.capitalize(this.moment.duration(this.moment().diff(visitStartTime)).humanize());
        }

        onPaginateCall() {
            this.tablePromise = this.frontDeskService.patientAppointments(this.query)
                .then(data => {
                    this.size = data['count'];
                    this.appointments = data['items'];
                });
        }

        removeAppointment(appointment) {
            this.tablePromise = this.frontDeskService.removeAppointment(appointment.id)
                .then(() => this.ws.pub("frontdesk.Calendar.notify", {
                        cmd: DELETE,
                        type: APPOINTMENT,
                        event: appointment.id,
                        event_type: appointment.event_type
                    },
                    false
                ).then(() => this.onPaginate()));
        }

        // patient related
        toPatient() {
            this.dialog.hide(_.get(this.patient, "id"));
        }


        // measurement Tab
        addMeasurement(ev) {
            this.measureService.measurementsComponent.next(ev);
        }

        // dental schema callbacks
        uploadFiles(ev, context) {
            const contextHandled = _.assign({dental_consultation: _.get(this.consultations, '0.id')}, context || {});

            this.dentalUtilsService.openUploadDialog(
                this.currentPatient, this.consultations, this.plans, contextHandled, ev
            );
        }

        toothHistoric(tooth, ev) {
            this.dentalUtilsService.toothHistoric(this.currentPatient, this.condition, this.consultations, tooth, ev);
        }

        showContextFiles(context) {
            this.filesTab = true;
            this.fileFilter = context;
        }

        associateFiles(ev) {
            const contextHandled = {dental_consultation: _.get(this.consultations, '0.id')};

            this.dentalUtilsService.openUploadDialog(
                this.currentPatient, this.consultations, this.plans, contextHandled, ev
            );
        }

        viewImage(ev) {
            const file = {name: '', mime: 'image/png', url: `/api/patient/${this.currentPatient}/data/`};

            const dialog = _.assign({}, VISUALIZE_FILE, {
                targetEvent: ev,
                locals: {files: _.castArray(file), fileIndex: 0, allowEdit: false}
            });

            this.dialog.show(dialog, _.noop);
        }

        selectPlan(plan) {
            this.promise = this.dentalService.planDetail(plan)
                .then(data => {
                    this.currentPlan = data;
                    this.dentalUtilsService.proceduresApplied(
                        'currentEditPlan', this.chartID, this.condition, this.consultations, data
                    ).then(style => this.schema = style);
                });
        }

        isSelected(tab) {
            return tab === this.defaultTab;
        }

        isActivated(tab, contract) {
            return !this.disabledTab.includes(tab) && contract;
        }

        // copy text related
        copyText(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.toast.show(this.simpleToast);
                });
        }
    }

    module.exports = {
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: EntryResumeCtrl,
        template: require("frontdesk/views/entry-resume-dialog.tpl.html"),
    };

})();
