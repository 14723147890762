/**
 * Created by amine on 13/06/2022.
 */
(function () {
    'use strict';

    const FILTER_DIALOG = require('stand-alone/technical-file/dialogs/advanced-filter/advanced-filter-dialog');

    class AdvancedFilter {
        constructor($mdDialog) {
            this.require = [];
            this.restrict = "A";
            this.scope = {
                query: "=advancedFilter",
                changed: "&?"
            };

            this.dialog = $mdDialog;
        }

        static create() {
            return new AdvancedFilter(...arguments);
        }

        link($scope, $element) {
            if (!_.isObject($scope.query)) $scope.query = {};
            _.set($scope.query, "advanced", {});
            $scope.model = {};

            $scope.changed = $scope.changed || _.noop;

            $element.on("click", event => {
                this.dialog.show(_.assign({}, FILTER_DIALOG, {
                    targetEvent: event,
                    locals: {
                        model: $scope.model
                    }
                })).then(({query, model}) => {
                    if(_.every(query,_.isEmpty)) return false;

                    $scope.model = model;
                    $scope.query.advanced = query;
                    $scope.changed();
                }, _.noop);
            });

            $scope.$on('$destroy', () => {
                $element.off("click");
            });
        }
    }

    AdvancedFilter.create.$inject = ["$mdDialog"];

    module.exports = AdvancedFilter.create;

})();
