(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    const STATES = [
        {
            "name": "patient_affectation",
            "label": "treating_physician",
        },
        {
            "name": "patient_referring_affectation",
            "label": "current_patient_referring_contact",
        }
    ];

    class PathologiesCtrl {
        constructor(genericStatesService, system, $translate, $scope, $state, $stateParams, moment) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.states = STATES;
            this.activeState = _.get(this.states, '0');
            this.currentState = genericStatesService.getCurrentState("patient_affectation");

            this.query = {
                start: $stateParams.start || null,
                end: $stateParams.end || null,
            };

            this.headers = {
                _id: {
                    type: "number",
                    caption: this.translate.instant('unique_id')
                },
                fullname: {
                    type: "string",
                    caption: this.translate.instant('dcm_patient_full_name')
                },
                contact_fullname: {
                    type: "string",
                    caption: this.translate.instant('daily_workflow.physician')
                }
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().toDate();
            this.getData();
        }


        getSlice() {
            return {
                "expands": {
                    "expandAll": true,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": [
                    {
                        "uniqueName": "contact_fullname"
                    },
                    {
                        "uniqueName": "fullname"
                    }
                ],
                "columns": [
                    {
                        "uniqueName": "Measures"
                    }
                ],
                "measures": [
                    {
                        "uniqueName": "total",
                        "caption": this.translate.instant("states_total"),
                        "individual": true,
                        "format": "integer",
                        "formula": "count(\"fullname\")",
                    }

                ],
            };
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getContactPhysician(_.assign({}, this.query, {data_source: this.activeState.name}))
                .then(data => {
                    if (onlyData) {
                        this.$dataSource.next({
                            onlyData: true,
                            data: data
                        });
                    } else {
                        this.$dataSource.next({
                            slice: this.getSlice(),
                            data: data,
                            headers: this.headers
                        });
                    }
                });
        }

        handleFileName() {
            return `${this.translate.instant('states_pathologies')}`;
        }

        handleStatesChange() {
            this.scope.$applyAsync(() => {
                this.query = {
                    start: null,
                    end: null
                };
                this.queryChanged();
            });
        }

        queryChanged() {
            const start = this.moment(this.query.start, this.dateFormat);
            const end = this.moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) this.query.end = start.endOf('month').format(this.dateFormat);

            this.$state.transitionTo(this.$state.current.name, this.query, {
                notify: false
            }).then(() => this.getData(true));
        }

        onComplete(instance) {
            instance.collapseData("pathology")
        }
    }

    module.exports = PathologiesCtrl;
})();