/**
 * Created by amine on 02/11/2020.
 */
(function () {

    'use strict';


    class MedicalCareFilesCtrl {
        constructor(medicalCareService, patientService, configService, $q, $state, $transition$, $scope) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.q = $q;
            this.state = $state;
            this.scope = $scope;

            this.currentPatient = _.get($transition$.params('to'), "pId");
            this.config = {};
            this.items = [];

            this.show = false;
            this.context = null;
            this.extraContext = null;
        }

        static get $inject() {
            return ["medicalCareService", "patientService", "configService", "$q", "$state", "$transition$", "$scope"];
        }

        $onInit() {
            this.medicalCareService.linkSubject.next('files');
            const formSubscription = this.medicalCareService.formSubject.subscribe(data => {
                const type_ = _.get(data, "type");
                const item = _.get(data, "item");

                if (type_ && type_ === 'plan') {
                    this.context = {medical_care_plan: item.id};
                    this.extraContext = {patient: this.currentPatient};
                } else {
                    this.context = {patient: this.currentPatient};
                    this.extraContext = {};
                }
                this.show = true;
            });


            this.scope.$on("$destroy", () => formSubscription.unsubscribe())
        }
    }

    module.exports = MedicalCareFilesCtrl;

})();
