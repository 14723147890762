(function () {

    'use strict';

    class FormsContainerCtrl {
        constructor($q, $transition$, $state, visitService, formsService, mnWebSocket, $scope) {
            this.state = $state;
            this.$q = $q;
            this.visitService = visitService;
            this.formsService = formsService;
            this.mnWebSocket = mnWebSocket;
            this.items = [];
            this.isLocked = true;
            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.category = _.get($transition$.params('to'), 'category');

            $scope.$on('$destroy', () => this.#onDestroy());
        }

        static get $inject() {
            return ["$q", "$transition$", "$state", "visitService", "formsService", "mnWebSocket", "$scope"];
        }

        $onInit() {
            this.items = []
            this.templates = [];
            this.newFormSubscription = this.formsService.newFormSubject.subscribe(this.handleNewForm.bind(this));
            this.editFormSubscription = this.formsService.editFormSubject.subscribe(this.handleItemEdited.bind(this));
            this.deleteFormSubscription = this.formsService.deleteFormSubject.subscribe(this.handleItemDeleted.bind(this));
            this.currentPatientSubscription = this.visitService.currentPatientSubject.subscribe(data => this.patient = data);
            let promises = [this.formsService.getFormsTemplates(), this.formsService.getForms(this.currentPatient)];

            this.promise = this.$q
                .all(promises)
                .then(data => {
                    this.templates = this.#removeHiddenTemplate(data[0]);
                    this.originalItems = data[1];
                    this.items = this.groupForms(this.originalItems);
                });
            this.mnWebSocket.sub('forms.FormTemplate.reload_templates', msg => {
                if (msg.reload) {
                    this.formsService.getFormsTemplates().then(data => this.templates = this.#removeHiddenTemplate(data));
                }
            });
        }

        #onDestroy() {
            this.currentPatientSubscription.unsubscribe();
            this.newFormSubscription.unsubscribe();
            this.editFormSubscription.unsubscribe();
            this.deleteFormSubscription.unsubscribe();

            // this.mnWebSocket.sub('forms.formTemplate.templates_notify'); templates_notify function missing
        }

        #removeHiddenTemplate(data) {
            return _.filter(data, value => {
                if (!this.category) return !value.is_hidden;

                return this.category === _.get(value, "category.id");
            });
        }

        editItem(f) {
            this.formsService.editState(f, true);
        }

        deleteItem(f) {
            this.formsService.deleteForm(f.id).then(_.noop);
        }

        newForm(t) {
            this.formsService.editState(null, true, t);
        }

        handleNewForm(item) {
            let index = _.findIndex(this.originalItems, {id: item.id});
            if (index === -1) {
                this.originalItems = _.concat(item, this.originalItems);
                this.currentForm = item.id;
            } else this.originalItems[index] = item;

            this.items = this.groupForms(this.originalItems);
        }

        handleItemDeleted(pk) {
            _.remove(this.originalItems, {id: pk});
            if (this.currentForm === pk) {
                this.state.go("app.visit.forms", {}).then(_.noop);
            }

            this.items = this.groupForms(this.originalItems);
        }

        handleItemEdited(item) {
            this.currentForm = item;
        }

        groupForms(items) {
            let groups = _.groupBy(_.cloneDeep(items), "template.id");

            return _.chain(groups)
                .reduce((result, value) => {
                    let category = _.chain(value[0]).get("template").pick(["name", "id"]).value() || {name: "-"}
                    category.items = value;
                    category.category = _.get(value, "0.category_id");
                    result.push(category);

                    return result;
                }, [])
                .sortBy("name")
                .filter(value => {
                    if (!this.category) return true;

                    return parseInt(this.category) === _.get(value, "category");
                })
                .value();
        }
    }

    module.exports = FormsContainerCtrl;

})();
