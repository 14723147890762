/**
 * Created by amine on 27/10/2020.
 */
(function () {

    'use strict';


    class MedicalCareFormCtrl {
        constructor(medicalCareService, patientService, configService, $translate, moment, system, $q, $state, $transition$, $scope) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.translate = $translate;
            this.moment = moment;
            this.q = $q;
            this.state = $state;
            this.$parent = $scope.$parent.vm;
            //this.transition = $transition$;

            this.dateFormat = system['date_format'].js;

            this.type = _.get($transition$.params('to'), "type");
            this.currentPatient = _.get($transition$.params('to'), "pId");
            this.currentItemId = _.get($transition$.params('to'), "id");
            this.quotationId = _.get($transition$.params('to'), "copy");
            this.serviceOrder = _.get($transition$.params('to'), "serviceOrder");
            this.item = _.get($transition$.params('to'), "item");
        }

        static get $inject() {
            return ["medicalCareService", "patientService", "configService", "$translate", "moment", "system", "$q", "$state", "$transition$", "$scope"];
        }

        $onInit() {
            let promise = null;
            if (this.currentItemId) {
                if (this.item) {
                    promise = this.q.when(this.item);
                } else {
                    promise = this.medicalCareService.get(this.currentItemId, this.type);
                }
            } else if (this.quotationId) {
                if (this.item) {
                    promise = this.q.when(this.item);
                } else {
                    promise = this.medicalCareService.get(this.quotationId, 'quotation')
                        .then(item => {
                            const newItem = this.medicalCareService.duplicateItem(item);
                            this.useQuotation(newItem, false);

                            return newItem;
                        });
                }
            } else {
                const title = (this.type === 'plan' ? 'medical_care.default_title' : 'medical_care.default_quotation_title');

                promise = this.q.when({
                    creation_date: this.moment().format(this.dateFormat),
                    title: this.translate.instant(title, {date: this.moment().format(this.dateFormat)}),
                    patient: this.currentPatient,
                    cures: [],
                    financial_status: {
                        gross_total: 0,
                        total: 0,
                        global_discount: 0,
                        paid_amount: 0,
                        is_exempt: false
                    }
               ,..._.assignIn({}, this.serviceOrder && !this.currentItemId ?{service_order:this.serviceOrder}:{}) })

            }

            this.$parent.containerPromise = promise.then(item => {
                this.item = item;
                this.medicalCareService.linkSubject.next(this.type === 'plan' ? 'plan_form' : 'quotation_form');
                this.medicalCareService.formSubject.next({
                    type: this.type,
                    item: this.item
                })
            }, () => this.state.go("app.visit.medical-care.list", {type: this.type}));
        }

        onSave(item) {
            this.state.go("app.visit.medical-care.form", {
                type: item.type,
                id: item.id,
                copy: null,
                item: item
            });
        }

        formClosed(saved) {
            this.state.go("app.visit.medical-care.list", {type: this.type});
        }

        useQuotation(newItem, redirect = true) {
            newItem.type = 'plan';
            newItem.title = this.translate
                .instant('medical_care.default_title', {date: newItem.creation_date});

            if (redirect) this.state.go("app.visit.medical-care.form", {
                type: 'plan',
                id: null,
                copy: newItem.quotation,
                item: newItem
            });
        }
    }

    module.exports = MedicalCareFormCtrl;

})();
