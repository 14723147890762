(function () {

    'use strict';

    class ProcedureSearchCtrl {
        constructor(medicalCareService, configService, $q, $mdDialog, $translate) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.q = $q;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.cancel = () => $mdDialog.cancel();

        }

        static get $inject() {
            return ["medicalCareService", "configService", "$q", "$mdDialog", "$translate"];
        }

        $onInit() {
            this.global_search = "";
            const promises = [
                this.medicalCareService.getProcedureGroups(),
                this.medicalCareService.getProcedures(),
                this.configService.getByHttp('medical_care_config')
            ];

            this.q.all(promises)
                .then(data => {
                    this.config = data[2] || this.medicalCareService.DEFAULT_CONFIG;

                    this.procedureGroups = this.orderList(data[0], 'groups');
                    this.procedures = data[1];

                    this.procedureGroups = _.map(this.procedureGroups, group => {
                        return _.assign(group, {procedures: _.filter(this.procedures, ['group.id', group.id])});
                    });

                    this.handleSearch();
                });
        }

        selectProcedures() {
            this.dialog.hide(
                _.chain(this.procedureGroups)
                    .map("procedures")
                    .flatten()
                    .filter(["active", true])
                    .value()
            );
        }

        chooseProcedure(item) {
            item.active = !item.active;
        }

        handleSearch() {
            this.isSearching = this.global_search.length > 0;

            if (!this.isSearching) {
                this.items = this.procedureGroups;
                return false;
            }

            this.items = this.medicalCareService.filterMenu(this.global_search, this.procedureGroups);

        }

        orderList(list, key) {
            const order = this.config[`${key}_list_order`];

            return this.medicalCareService.orderList(list, order)
        }

    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: ProcedureSearchCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("medical-care/views/medical-care-procedure-search-dialog.tpl.html"),
    };

})();