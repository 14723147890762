/**
 * Created by amine on 28/07/2021.
 */
(function () {

    'use strict';

    const CALENDAR_DURATION_DIALOG = require('../dialogs/calendar-duration-dialog');
    const APPOINTMENT_AVAILABILITY_DIALOG = require('../dialogs/appointement-availability-dialog');

    class AppointmentFormService {
        constructor($q, moment, $mdDialog, configService, system, mnWebSocket) {
            this.q = $q;
            this.dialog = $mdDialog;
            this.configService = configService;
            this.ws = mnWebSocket;
            this.moment = moment;

            this.dateFormat = system.date_format.js;
            this.timeFormat = system.time_format.js;
        }

        static get $inject() {
            return ["$q", "moment", "$mdDialog", "configService", "system", "mnWebSocket"];
        }

        handleTimeRange(appointment, timeRange, timeout) {
            const deferred = this.q.defer();

            appointment = _.cloneDeep(appointment);
            timeRange = _.cloneDeep(timeRange);

            const assignObject = {
                date: timeRange.start.format(this.dateFormat),
                start_time: timeRange.start.format(this.timeFormat),
                end_time: timeRange.end.format(this.timeFormat)
            }

            const isToday = this.checkToday(appointment);

            if (timeout) {
                setTimeout(() => {
                    deferred.resolve({
                        assignObject: this.#setResource(assignObject, timeRange),
                        isToday
                    })
                }, 50);
            } else {
                this.#setResource(assignObject, timeRange);
                deferred.resolve({
                    assignObject: this.#setResource(assignObject, timeRange),
                    isToday
                });
            }

            return deferred.promise;
        }

        checkToday(appointment) {
            let appointmentDate = appointment.date;
            if (!this.moment.isMoment(appointmentDate)) appointmentDate = this.moment(appointmentDate, "DD/MM/YYYY")

            return appointmentDate.isSame(this.moment(), "day");
        }

        changeInterval(appointment, assignObject = null) {
            appointment = _.cloneDeep(appointment);
            const defaultReason = _.get(this.configService.defaultValues, "entry.reason", null);
            let reason = _.get(appointment, 'reason');

            if (!reason && !!defaultReason) {
                appointment.reason = reason = defaultReason;
            }

            if (!!reason && !_.isNil(appointment.start_time)) {

                const duration = _.get(reason, 'appointment_duration', 20);
                appointment.end_time = this.moment(appointment.start_time, this.timeFormat)
                    .add(duration, 'minutes')
                    .format(this.timeFormat);

                let reasonAgenda = _.get(reason, 'default_agenda');
                if (reasonAgenda) appointment.agenda = reasonAgenda;
            }

            if (!!assignObject) {
                return _.assign({}, appointment, assignObject);
            }

            return appointment;
        }

        showDurationCalendar(appointment, ev) {
            const deferred = this.q.defer();

            appointment = _.cloneDeep(appointment);

            this.dialog
                .show(
                    _.assign({}, CALENDAR_DURATION_DIALOG, {
                        targetEvent: ev,
                    })
                )
                .then(data => {
                    const duration = this.moment.duration(data.end.diff(data.start)).asMinutes();
                    const reasonDuration = _.get(appointment, 'reason.appointment_duration', 20);

                    if (duration < reasonDuration) data.end = data.start.clone().add(reasonDuration, 'minutes');

                    this.handleTimeRange(appointment, data, false)
                        .then(data => deferred.resolve(data));
                }, () => deferred.reject());

            return deferred.promise;
        }

        showAvailabilities(appointment, ev) {
            const deferred = this.q.defer();

            appointment = _.cloneDeep(appointment);

            this.dialog
                .show(
                    _.assign({}, APPOINTMENT_AVAILABILITY_DIALOG, {
                        targetEvent: ev,
                        locals: {
                            query: {
                                days: 3,
                                start_date: appointment.date || this.moment().format(this.dateFormat),
                                duration: _.get(appointment, 'reason.appointment_duration', 20),
                                physician: _.get(appointment, 'physician.id'),
                            }
                        }
                    })
                )
                .then(
                    data => deferred.resolve(_.assign({}, appointment, data)),
                    () => deferred.reject()
                );

            return deferred.promise;
        }

        notifyCalendar(cmd, type, data) {
            const event = data.id;
            const event_type = data.event_type;

            this.ws.pub("frontdesk.Calendar.notify", {cmd, type, event, event_type}, false);
        }

        #setResource(data, timeRange) {
            if (timeRange.agenda) data.agenda = timeRange.agenda;
            if (timeRange.physician) data.physician = timeRange.physician;

            return data;
        }
    }

    module.exports = AppointmentFormService;
})();
