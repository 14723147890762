/**
 * Created by amine on 26/07/2021.
 */
(function () {

    'use strict';

    const DEFAULT_VALUE = {
        id: null,
        date: null,
        start_time: null,
        end_time: null,
        patients: [],
        reason: null,
        agenda: null,
        physician: null,
        no_patients: false
    };

    const {UPDATE, APPOINTMENT, WAITING_LIST, DELETE} = require('stand-alone/calendar/utils/consts');


    class GroupAppointmentDialogCtrl {
        constructor($mdDialog, frontDeskService, appointmentFormService, groupAppointmentService, $translate, moment, system) {
            this.dialog = $mdDialog;
            this.frontDeskService = frontDeskService;
            this.appointmentFormService = appointmentFormService;
            this.groupAppointmentService = groupAppointmentService;
            this.moment = moment;

            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
            this.hasSportData = _.get(system, "active_modules.has_sport_data", false);

            this.choose = null;
            this.isToday = false;

            this.query = {
                limit: 5,
                page: 1,
                order: "last_name",
                orderKey: "last_name",
                orderDir: "asc"
            }
            this.options = [5, 10, 20];
            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            this.onReorder = order => this.#onReorderEvent(order);
            this.onPaginate = (page, limit) => this.#onPaginateEvent(page, limit);
        }

        static get $inject() {
            return [
                "$mdDialog", "frontDeskService", "appointmentFormService", "groupAppointmentService", "$translate",
                "moment", "system"
            ];
        }

        get patients() {
            const pageIndex = this.query.page - 1
            const start = (pageIndex * this.query.limit);

            return _.chain(this.appointment.patients)
                .orderBy(this.query.orderKey, this.query.orderDir)
                .slice(start, start + this.query.limit)
                .value() || [];
        }

        $onInit() {
            this.toPractice = false;
            this.appointmentId = this.appointmentId || null;
            this.appointment = this.appointment ? _.assign({}, DEFAULT_VALUE, this.appointment) : DEFAULT_VALUE;

            if (_.isNumber(this.appointmentId)) {
                this.groupAppointmentService
                    .getAppointment(this.appointmentId)
                    .then(data => {
                        this.appointment = data;

                        this.checkToday();
                    });
            } else {
                this.timeRange = this.timeRange || null;
                if (!this.appointment.date) this.appointment.date = this.moment().format(this.dateFormat);
                if (!this.appointment.start_time) this.appointment.start_time = this.moment().minutes(0).format(this.timeFormat);
                if (!_.isArray(this.appointment.patients)) this.appointment.patients = [];

                if (!!this.timeRange) this.handleTimeRange(this.timeRange, true);
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        submit($event) {
            this.groupAppointmentService.handleAppointment(this.appointment)
                .then(data => {
                    this.appointment = data;

                    this.#notifyCalendar();
                    this.dialog.cancel();

                    if (this.toPractice) this.createEntries($event);
                });
        }

        remove() {
            this.groupAppointmentService.removeAppointment(this.appointment)
                .then(data => {
                    this.#notifyCalendar(DELETE);

                    this.dialog.hide(data);
                });
        }

        patientsCallback(patients) {
            if (!_.isArray(patients) && _.has(patients, "external_api_identifier")) patients = [patients];

            patients.forEach(patient => {
                _.pushOrUpdate(this.appointment.patients, patient);
            });
        }

        patientResume(patient, ev) {
            this.frontDeskService.entryResume({'patient-id': patient.id, onlyResume: true}, ev, {multiple: true});
        }

        removePatient(index, ev) {
            ev.stopPropagation();
            this.appointment.patients.splice(index, 1);
        }

        handleTimeRange(timeRange, timeout) {
            this.appointmentFormService
                .handleTimeRange(this.appointment, timeRange, timeout)
                .then(data => {
                    // @todo to be fixed post GroupAppointment tests
                    this.appointment = _.assign({}, this.appointment, data.assignObject);
                    this.isToday = data.isToday;
                });
        }

        checkToday() {
            this.isToday = this.appointmentFormService.checkToday(this.appointment)
        }

        changeInterval() {
            this.appointment = this.appointmentFormService.changeInterval(this.appointment);
        }

        showDurationCalendar(ev) {
            this.appointmentFormService
                .showDurationCalendar(this.appointment, ev)
                .then(data => {
                    this.appointment = _.assign({}, this.appointment, data.assignObject);
                    this.isToday = data.isToday;
                }, _.noop);
        }

        showAvailabilities(ev) {
            this.appointmentFormService
                .showAvailabilities(this.appointment, ev)
                .then(data => this.appointment = data, _.noop);
        }

        patientEntered(patient) {
            return !!this.appointment.patients_entered ? this.appointment.patients_entered.includes(patient.id) : false;
        }

        handleWaitingToggle() {
            this.toPractice = false;

            if (!this.appointment.is_waiting_list && _.isNil(this.appointment.start_time)) {
                this.appointment.start_time = this.moment().minutes(0).format(this.timeFormat);

                this.changeInterval();
            }
        }

        createEntries(ev) {
            return this.groupAppointmentService.createEntries(this.appointment, ev, this.toPractice, null)
        }

        #onReorderEvent(order) {
            const orderDir = this.query.order.includes("-") ? "desc" : "asc";
            const orderKey = order.replace("-", "");
            this.query = _.assign(this.query, {order, orderDir, orderKey});
        }

        #onPaginateEvent(page, limit) {
            this.query = _.assign(this.query, {page, limit});
        }

        #notifyCalendar(cmd) {
            if (!cmd) cmd = this.appointment.is_waiting_list ? WAITING_LIST : UPDATE
            this.appointmentFormService.notifyCalendar(
                cmd,
                APPOINTMENT,
                this.appointment
            )
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: GroupAppointmentDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("frontdesk/views/group-appointment-dialog.tpl.html"),
    };

})();