(function () {

    'use strict';

    const angular = require('angular');
    const FormsContainerCtrl = require("./controllers/forms-container/FormsContainerCtrl");
    const FormDetailCtrl = require("./controllers/form-detail/FormDetailCtrl");
    const GeneralFormsContainerCtrl = require("./controllers/general-forms-container/GeneralFormsContainerCtrl");
    const GeneralFormDetailCtrl = require("./controllers/general-forms-detail/GeneralFormDetailCtrl");

    const formsService = require("./services/formsService");

    const formTemplate = require('./components/form-template');
    const formLinks = require('./components/form-links');
    const formBlock = require('./components/form-block');

    angular
        .module("medinet")

        .component('formTemplate', formTemplate)
        .component('formLinks', formLinks)
        .component('formBlock', formBlock)

        .service('formsService', formsService)

        .controller('FormsContainerCtrl', FormsContainerCtrl)
        .controller('FormDetailCtrl', FormDetailCtrl)
        .controller('GeneralFormsContainerCtrl', GeneralFormsContainerCtrl)
        .controller('GeneralFormDetailCtrl', GeneralFormDetailCtrl);


})();