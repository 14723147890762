(function () {

    'use strict';

    const STATES = [
        {
            "name": "all_objects",
            "label": "states_all_objects",
        },
        {
            "name": "only_care_sheet",
            "label": "states_only_care_sheet",
        },
        {
            "name": "all_objects_count",
            "label": "states_all_objects_count",
        }
    ];

    const {BehaviorSubject} = require("rxjs");

    class InsuredReportCtrl {
        constructor(genericStatesService, system, $translate, $scope, $state, $stateParams, moment) {
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.currentState = genericStatesService.getCurrentState("insured_report");

            this.isChart = false;
            this.states = STATES;
            this.activeState = _.get(this.states, '0');


            this.onlyInsured = !!$stateParams.onlyInsured || true;
            this.query = {
                start: $stateParams.start || this.moment().startOf('month').format(this.dateFormat),
                end: $stateParams.end || this.moment().endOf('month').format(this.dateFormat),
            };

            this.headers = {
                all_objects: {
                    _id: {
                        type: "string",
                        caption: this.translate.instant('unique_id')
                    },
                    str_id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_fullname: {
                        type: "string",
                        caption: this.translate.instant('dcm_patient_full_name')
                    },
                    physician_fullname: {
                        type: "string",
                        caption: this.translate.instant('daily_workflow.physician')
                    },
                    organization: {
                        type: "string",
                        caption: this.translate.instant('organization')
                    },
                    date: {
                        type: "date string",
                        caption: this.translate.instant('payment_date')
                    },
                    total_amount: {
                        type: "number",
                        caption: this.translate.instant('payment_total_amount')
                    },
                    paid_amount: {
                        type: "number",
                        caption: this.translate.instant('payment_paid_amount')
                    },
                    remaining_amount: {
                        type: "number",
                        caption: this.translate.instant('payment_unpaid_amount')
                    },
                    global_discount: {
                        type: "number",
                        caption: this.translate.instant('payment_discount')
                    },
                    is_exempt: {
                        type: "boolean",
                        caption: this.translate.instant('exempt_visit_label')
                    },
                },
                only_care_sheet: {
                    _id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_fullname: {
                        type: "string",
                        caption: this.translate.instant('dcm_patient_full_name')
                    },
                    physician_fullname: {
                        type: "string",
                        caption: this.translate.instant('daily_workflow.physician')
                    },
                    sheet_date: {
                        type: "date string",
                        caption: this.translate.instant('sheet_date')
                    },
                    organization: {
                        type: "string",
                        caption: this.translate.instant('organization')
                    },
                    total_amount: {
                        type: "number",
                        caption: this.translate.instant('payment_total_amount')
                    }
                },
                all_objects_count: {
                    _id: {
                        type: "number",
                        caption: this.translate.instant('unique_id')
                    },
                    patient_count: {
                        type: "number",
                        caption: this.translate.instant('insured_patient_count')
                    },
                    organization_name: {
                        type: "string",
                        caption: this.translate.instant('organization_name')
                    }
                },
            };
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().toDate();
            this.getData();
        }


        getSlice() {
            return {
                "expands": {
                    "expandAll": true,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "rows": this.getRows(),
                "columns": [
                    {
                        "uniqueName": "Measures"
                    }
                ],
                "measures": this.getMeasures()
            };
        }


        getRows() {
            switch (this.activeState.name) {
                case 'all_objects':
                    return [
                        {
                            "uniqueName": "physician_fullname"
                        },
                        {
                            "uniqueName": "organization"
                        },
                        {
                            "uniqueName": "date"
                        },
                        {
                            "uniqueName": "patient_fullname"
                        },
                        {
                            "uniqueName": "str_id"
                        }
                    ];
                case 'only_care_sheet':
                    return [
                        {
                            "uniqueName": "physician_fullname"
                        },
                        {
                            "uniqueName": "organization"
                        },
                        {
                            "uniqueName": "sheet_date"
                        },
                        {
                            "uniqueName": "patient_fullname"
                        }
                    ];
                case 'all_objects_count':
                    return [
                        {
                            "uniqueName": "organization_name"
                        }
                    ];
            }
        }

        getMeasures() {
            switch (this.activeState.name) {
                case 'all_objects':
                    return [
                        {
                            "uniqueName": "total_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregate": "sum",
                        },
                        {
                            "uniqueName": "paid_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregate": "sum",
                        },
                        {
                            "uniqueName": "global_discount",
                            "individual": false,
                            "format": "currency",
                            "aggregate": "sum",
                        },
                        {
                            "uniqueName": "remaining_amount",
                            "individual": true,
                            "format": "currency",
                            "aggregation": "sum"
                        },
                        {
                            "uniqueName": "exempt_amount",
                            "individual": true,
                            "format": "currency",
                            "aggregation": "sum",
                        }
                    ];
                case 'only_care_sheet':
                    return [
                        {
                            "uniqueName": "total_amount",
                            "individual": false,
                            "format": "currency",
                            "formula": "sum(\"total_amount\")",
                        }
                    ];
                case 'all_objects_count':
                    return [
                        {
                            "uniqueName": "total_amount",
                            "caption": this.translate.instant("insured_patient_count"),
                            "individual": true,
                            "format": "integer",
                            "formula": "count(\"_id\")",
                        }
                    ];
            }
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getInsuredReport(
                _.assign(this.query, {
                    data_source: this.activeState.name,
                    only_insured: this.onlyInsured
                })
            ).then(data => {
                // console.log(data);
                if (onlyData) {
                    this.$dataSource.next({
                        onlyData: true,
                        data: data,
                    });
                } else {
                    this.$dataSource.next({
                        slice: this.getSlice(),
                        data: data,
                        headers: this.headers[this.activeState.name]
                    });
                }
            });
        }

        handleFileName() {
            const start = this.moment(this.query.start, this.dateFormat).format(this.genericStatesService.exportDateFormat);
            const end = this.moment(this.query.end, this.dateFormat).format(this.genericStatesService.exportDateFormat);

            return `${this.translate.instant('states_insured_patients_visits_file_label', {start, end})}`;
        }

        handleStatesChange() {
            this.onlyInsured = true;
            this.scope.$applyAsync(() => this.queryChanged(false));
        }

        queryChanged(onlyData = true) {
            const start = this.moment(this.query.start, this.dateFormat);
            const end = this.moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) this.query.end = start.endOf('month').format(this.dateFormat);

            this.$state.transitionTo(
                this.$state.current.name,
                _.assign(this.query, {onlyInsured: this.onlyInsured ? 1 : 0}),
                {notify: false}
            ).then(() => this.getData(onlyData));
        }

        onComplete(instance) {
            instance.collapseData("patient_fullname");
            if (this.activeState.name === "all_objects") {
                instance.collapseData("date");
            } else {
                instance.collapseData("sheet_date")
            }
        }

        customizeCells(cellBuilder, cellData) {
            if (cellData.type !== "header") {
                const column = _.get(cellData, "measure.uniqueName", "");
                if (cellData.isGrandTotalColumn && this.activeState.name === "all_objects") {
                    const detailsColumns = [
                        "paid_amount",
                        "remaining_amount",
                        "global_discount",
                        "exempt_amount"
                    ];

                    if (_.includes(detailsColumns, column)) {
                        _.remove(cellBuilder.classes, item => item === "grand-total-column");
                        cellBuilder.addClass("wdr-details")
                    }
                }

                if (this.activeState.name === "all_objects" && column === "exempt_amount") {
                    if (cellData.isGrandTotalRow) cellBuilder.addClass("wdr-exempt-total");
                    else cellBuilder.addClass("wdr-exempt-detail");
                }
            }
        }
    }

    module.exports = InsuredReportCtrl;
})();
