/**
 * Created by amine on 02/11/2020.
 */
(function () {

    'use strict';


    class MedicalCareMedicalFileCtrl {
        constructor(medicalCareService, patientService, configService, $q, $state, $transition$) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.patientService = patientService;
            this.q = $q;
            this.state = $state;

            this.currentPatient = _.get($transition$.params('to'), "pId");
            this.config = {};
            this.items = [];
        }

        static get $inject() {
            return ["medicalCareService", "patientService", "configService", "$q", "$state", "$transition$"];
        }

        $onInit() {
            this.medicalCareService.linkSubject.next("medical_file");
        }
    }

    module.exports = MedicalCareMedicalFileCtrl;

})();
